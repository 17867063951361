































































import L, { LatLng, divIcon, LeafletMouseEvent } from 'leaflet'
import Vue from 'vue'
//@ts-ignore
import { LMovingMarker } from 'vue2-leaflet-plugin-movingmarker'
import { LCircle, LPolyline, LMarker } from 'vue2-leaflet'
import {
  EDetectionTrailStyle,
  IDetection,
  IDetectionPosition,
  COLOR_THREAT,
  COLOR_FRIEND,
  EDetectionState
} from '@/store/modules/detection/types'
import anime from 'animejs'
import { mapActions, mapState } from 'vuex'
import { point } from '@turf/helpers'
import bearing from '@turf/bearing'
import booleanEqual from '@turf/boolean-equal'

const props = {
  position: {
    type: Object as () => IDetectionPosition,
    default: () => ({
      lat: 0,
      lng: 0
    })
  },
  detection: {
    type: Object as () => IDetection,
    default: () => {}
  },
  moveDuration: {
    type: Number,
    default: 650
  },
  maxRadius: {
    type: Number,
    default: 100
  },
  isThreat: {
    type: Boolean,
    default: true
  },
  trailStyle: {
    type: Number as () => EDetectionTrailStyle,
    default: EDetectionTrailStyle.LINE
  },
  hideLocationVariance: {
    type: Boolean,
    default: false
  },
  interactive: {
    type: Boolean,
    default: true
  },
  isCameraTracked: {
    type: Boolean,
    default: false
  }
}

const BAR_THRESHOLD_1 = 0.2
const BAR_THRESHOLD_2 = 0.4
const BAR_THRESHOLD_3 = 0.6
const BAR_THRESHOLD_4 = 0.8

export default Vue.extend({
  name: 'FusionDetectionDot',
  props,
  components: {
    LMarker,
    LMovingMarker,
    LCircle,
    LPolyline
  },
  data() {
    return {
      trails: [],
      defaultZoom: 1.25,
      confidence: {
        location: 0,
        object: 0
      },
      markerOptions: {
        zIndexOffset: 1000
      },
      iconWidth: 50,
      iconHeight: 50,
      timer: null,
      markerPosition: { lat: 0, lng: 0 },
      locationConfidenceRadius: 0
    }
  },
  created() {
    this.markerPosition = { lat: this.position.lat, lng: this.position.lng }
    this.trails.push(this.position)
  },
  computed: {
    ...mapState(['theme']),
    ...mapState('detection', ['selectedDetections']),
    detectionKey(): string {
      return this.detection.target_id
    },
    showTrailLine(): boolean {
      return (
        this.trailStyle === EDetectionTrailStyle.LINE ||
        this.trailStyle === EDetectionTrailStyle.BOTH
      )
    },
    showTrailDots(): boolean {
      return (
        this.trailStyle === EDetectionTrailStyle.DOTS ||
        this.trailStyle === EDetectionTrailStyle.BOTH
      )
    },
    selected(): boolean {
      return this.selectedDetections.includes(this.detectionKey)
    },
    detectionColor(): string {
      return this.isThreat ? COLOR_THREAT : COLOR_FRIEND
    },
    markerRef(): string {
      return `fusion-marker-${this.detectionKey}`
    },
    objectConfidence(): number {
      return this.detection.probability
    },
    locationConfidence(): number {
      return this.detection.location_variance
    },
    trailCoordinates() {
      return this.trails.map(trail => {
        return [trail.lat, trail.lng]
      })
    },
    bars(): number {
      let val = 0
      if (this.objectConfidence >= BAR_THRESHOLD_1) {
        val = 1
      }
      if (this.objectConfidence >= BAR_THRESHOLD_2) {
        val = 2
      }
      if (this.objectConfidence >= BAR_THRESHOLD_3) {
        val = 3
      }
      if (this.objectConfidence >= BAR_THRESHOLD_4) {
        val = 4
      }
      return val
    },
    useMilStd2525Icons(): boolean {
      return this.theme === 'MIL_STD_2525'
    },
    milStd2525Icon(): string {
      return this.isThreat ? 'detection-uav-threat.svg' : 'detection-uav-friendly.svg'
    },
    iconFolder(): string {
      if (this.useMilStd2525Icons) return 'mil-std-2525'
      return 'sensorfusion'
    },
    img(): string {
      if (this.useMilStd2525Icons) return this.milStd2525Icon

      return `${Math.round(this.detection.threat_level * 10)}/fusion_${this.bars}.svg`
    },
    selectorIcon(): L.DivIcon {
      return divIcon({
        className: `fusion-marker-style`,
        html: `<img src="${require('@/assets/sensorfusion/fusion_selector.svg')}" style="width:${this
          .iconWidth + 20}px;height:${this.iconHeight + 20}px"/>`,
        iconSize: [this.iconWidth + 20, this.iconHeight + 20]
      })
    },
    detectionIcon(): L.DivIcon {
      return divIcon({
        className: `fusion-marker-style ${
          this.isCameraTracked ? 'camera-tracked' : ''
        }`,
        html: `<img src="${require('@/assets/' + this.iconFolder + '/' +
          this.img)}" alt="${this.img}" style="width:${
          this.iconWidth
        }px;height:${this.iconHeight}px"/>`,
        iconSize: [this.iconWidth, this.iconHeight]
      })
    }
  },
  methods: {
    ...mapActions('detection', [
      'selectDetection',
      'addSelectedDetection',
    ]),
    positionUpdated(payload: LatLng) {
      this.markerPosition = payload
      this.trails.unshift({ lat: payload.lat, lng: payload.lng })
    },
    detectionClicked() {
      if (!this.interactive) return
      this.selectDetection(this.detectionKey)
    },
    getLocationRadius(confidence: number): number {
      return (1 - confidence) * this.maxRadius
    }
  },
  watch: {
    locationConfidence: {
      handler: function(variance: number) {
        anime({
          targets: this,
          locationConfidenceRadius: variance,
          easing: 'easeOutQuad',
          duration: 500
        })
      }
    },
    position: {
      handler: function(position: IDetectionPosition) {
        this.markerPosition = {
          lat: position.lat,
          lng: position.lng
        }
      }
    }
  }
})
